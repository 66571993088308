import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable, of} from 'rxjs';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { PaginationResultModel } from '../../../models/pagination/pagination-result.model';
import { HttpUtils } from '../../../utilities/http.utils';
import { DocumentModel } from '../../models/document.model';
import {IndividualInvolvementModel, PartialSignatureInfo} from '../../../models';
import { AUDIT_LOGS_PAGE, PORTAL_NAMES } from '../../../utilities';
import { UserInfoModel, UserInfoService } from 'c4p-portal-util';
import {AuditLog} from '../../models/audit-log.model';
import firebase from 'firebase/compat';
import UserInfo = firebase.UserInfo;

@Injectable()
export class DocumentService {
  public documents$ = new BehaviorSubject<{
    data: DocumentModel[];
    count: number;
  }>(null);

  public auditLogs$ = new BehaviorSubject<{
    data: AuditLog[];
    count: number;
  }>(null);
  public selectedDocument$ = new BehaviorSubject<DocumentModel>(null);

  // clientId, applicationId, userportal
  public documentParams$ = new BehaviorSubject<any>(null);

  // options used in the document dialog
  services: any[];
  categories: any[];
  private readonly documentUrl =
    this.environment.documentManagementApiUrl + '/documents';
  private readonly auditsUrl =
    this.environment.documentManagementApiUrl + '/audits/documents';

  private generateUrl = this.environment.documentManagementApiUrl + '/generate/';
  constructor(
    public http: HttpClient,
    @Inject('environment') private environment,
  ) {
  }

  public section() {
    return this.documentParams$.value?.section || '';
  }

  public isCarefile() {
    return (
      this.documentParams$.value && !!this.documentParams$.value.carefileId
    );
  }

  public getCarefileStatus() {
    return this.documentParams$?.value.carefileStatus;
  }

  public isEmployeePortal() {
    return (
      this.documentParams$.value &&
      this.documentParams$.value.userportal == 'employee'
    );
  }
  public isClientPortal() {
    return (
      this.documentParams$.value &&
      this.documentParams$.value.userportal == 'client'
    );
  }
  public getDocuments(paginationData: PaginationDataModel, filterData?: any) {
    let additionalOriginPath = this.isCarefile()
      ? '/carefile/:carefile_id'
      : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}`,
    });

    if (!filterData.applicationId) {
      filterData.applicationId = this.documentParams$.value.applicationId;
    }
    /*
      There is no need for this search param for getting all files
      there was a bug CARE-1165. When we add file before application is save we will have client.id of null
      thet was never updated after application is saved. now issue is fixed we will save client.id after save
      but for old data this needs to be inserted in collection in order to this query param works properly.
    */
    // filterData['client.id'] = this.documentParams$.value.clientId;

    if (this.documentParams$.value) {
      filterData.carefileId = this.documentParams$.value.carefileId;
    }
    if (this.documentParams$.value) {
      filterData.careprogramId = this.documentParams$.value.careprogramId;
    }

    if (filterData.carefileId) {
      filterData.requestor = 'cf';
    }
    // TODO: ADD HANDLING OF CAREFILE HERE
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    const received = this.http.get<PaginationResultModel<DocumentModel>>(
      this.documentUrl,
      { params, headers },
    );
    return received.pipe(
      map((x: PaginationResultModel<DocumentModel>) =>
        this.appendSignatureInfo(x),
      ),
    );
  }

  appendSignatureInfo(
    value: PaginationResultModel<DocumentModel>,
  ): PaginationResultModel<DocumentModel> {
    return {
      ...value,
      docs: value.docs.map((x) => this.appendSignatureInfoToDocument(x)),
    };
  }

  appendSignatureInfoToDocument(value: DocumentModel): DocumentModel {
    return { ...value, isSigned: value?.signatureLogs?.length > 0 };
  }

  public getDocumentUrl(documentId: string, mode: string) {
    let additionalOriginPath = this.isCarefile()
    ? '/carefile/:carefile_id'
    : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}/:document_id`,
      entity_id: documentId,
    });
    const queryParams = { mode: mode };

    return this.http.get(`${this.documentUrl}/${documentId}/download`, {
      headers,
      params: queryParams,
      responseType: 'blob',
    });
  }

  public deleteDocument(documentNumber: number | string) {
    let additionalOriginPath = this.isCarefile()
      ? '/carefile/:carefile_id'
      : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}/:document_id`,
      entity_id: documentNumber.toString(),
    });
    return this.http.delete<any>(`${this.documentUrl}/${documentNumber}`, {
      headers,
    });
  }

  public getDocument(id: number | string) {
    let additionalOriginPath = this.isCarefile()
      ? '/carefile/:carefile_id'
      : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}/:document_id`,
      entity_id: id.toString(),
    });
    return this.http.get<DocumentModel>(`${this.documentUrl}/${id}`, {
      headers,
    });
  }

  public updateDocument(document: DocumentModel) {
    let additionalOriginPath = this.isCarefile()
      ? '/carefile/:carefile_id'
      : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}/:document_id`,
      entity_id: document.id,
    });
    return this.http.put<DocumentModel>(
      `${this.documentUrl}/${document.id}`,
      document,
      { headers },
    );
  }

  public updateSharing(document: DocumentModel) {
    let additionalOriginPath = this.isCarefile()
      ? '/carefile/:carefile_id'
      : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}/:document_id/sharing`,
      entity_id: document.id,
    });
    return this.http.put<DocumentModel>(
      `${this.documentUrl}/${document.id}/sharing`,
      document,
      { headers },
    );
  }

  public updateDocumentClientData(entity_id, client) {
    let additionalOriginPath = this.isCarefile()
      ? '/carefile/:carefile_id'
      : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}`,
      entity_type: 'Documents',
      entity_id: entity_id,
    });
    const entity = this.isCarefile() || 'application';
    return this.http.put<DocumentModel>(
      `${this.documentUrl}/update-client/${entity}/${entity_id}`,
      client,
      { headers },
    );
  }

  public createDocument(document: DocumentModel) {
    let additionalOriginPath = this.isCarefile()
      ? '/carefile/:carefile_id'
      : '';
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents${additionalOriginPath}`,
    });
    document.applicationId = this.documentParams$.value.applicationId;
    document.carefileId = this.documentParams$.value.carefileId;
    document.careprogramId = this.documentParams$.value.careprogramId;
    document.client = <any>{ id: this.documentParams$.value.clientId };
    return this.http.post<DocumentModel>(`${this.documentUrl}`, document, {
      headers,
    });
  }

  public crateTemplateConfiguration(templateConfiguration: any) {

    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/generate/invoice/template/configuration',
      entity: 'TemplateConfiguration'
    });

    return this.http.post<any>(`${this.generateUrl}invoice/template/configuration`, templateConfiguration, {
      headers,
    });
  }
  public updateTemplateConfiguration(templateConfiguration: any) {

    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/generate/invoice/template/configuration',
      entity: 'TemplateConfiguration'
    });

    return this.http.put<any>(`${this.generateUrl}invoice/template/configuration`, templateConfiguration, {
      headers,
    });
  }

  public getTemplateConfigurations(filterParams?: any) {

    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/generate/invoice/template/configuration',
      entity: 'TemplateConfiguration'
    });

    return this.http.get<any>(`${this.generateUrl}invoice/template/configuration`, {
      headers,
    });
  }

  public deleteTemplateConfigurations(id:string,tenantId: string,) {

    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/generate/invoice/template/configuration/:configuration_id',
      entity: 'TemplateConfiguration'
    });

    return this.http.delete<any>(`${this.generateUrl}invoice/template/configuration/${id}`, {
      headers,
    });
  }

  public downloadDocument(link: string, document: DocumentModel) {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/pdf',
    });

    return this.http.get(link, { headers, responseType: 'blob' });
  }

  public signPdf(
    documentId: string,
    signatureInfo: PartialSignatureInfo,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/documents/:id/sign-pdf',
      entity_type: 'Document',
      entity_id: documentId,
    });
    return this.http.post<any>(
      `${this.documentUrl}/${documentId}/sign-pdf`,
      signatureInfo,
      { headers },
    );
  }

  public getAuditLogs(
    paginationData: PaginationDataModel,
    filterData?: any,
    currentPage?: any,
  ) {
    if (!filterData.documentId)
      throw new Error('Missing documentId from filterData');

    let headers: HttpHeaders;

    if (currentPage === AUDIT_LOGS_PAGE.APPLICATION) {
      headers = new HttpHeaders({
        origin_path: '/audits/application/:applicationId/documents/:id',
      });
    } else if (currentPage === AUDIT_LOGS_PAGE.CAREFILES) {
      headers = new HttpHeaders({
        origin_path: '/audits/carefiles/:carefileId/documents/:id',
      });
    }
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
      'documentId',
    );

    return this.http.get<PaginationResultModel<AuditLog>>(
      `${this.auditsUrl}/${filterData.documentId}`,
      { params, headers },
    );

  }

  public revokeSignature(documentId: string, userId: string, userInfo: UserInfoModel): Observable<any>{

      let path = `/signature/${documentId}/revoke`;
      const headers: HttpHeaders = new HttpHeaders({
        origin_path: `/documents/signature/:document_id/revoke`,
        entity_id: documentId,
        user_id: userInfo.id,
        tenant_id: userInfo.tenantId,
        full_name: userInfo.fullName
      });

      return this.http.put<DocumentModel>(
        `${this.documentUrl}${path}`,
        { userId: userId },
        { headers },
      );
    }

  public notifySignature(documentId: string, userId: string, userInfo: UserInfoModel): Observable<any>{

    let path = `/signature/${documentId}/resend`;
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/documents/signature/:document_id/resend`,
      entity_id: documentId,
      user_id: userInfo.id,
      tenant_id: userInfo.tenantId,
      full_name: userInfo.fullName
    });

    return this.http.put<DocumentModel>(
      `${this.documentUrl}${path}`,
      { userId: userId },
      { headers },
    );
  }

}
