import { ClientInfoService } from './../client-info/client-info.service';
import { LanguageService } from './../language/language.service';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';
import { ITableView } from '../../general-components/table-view/table-view.component';
import { ClientNetworkModel } from '../../models/client-network/client-network.model';
import { EmployeeModel } from '../../models/employee/employee.model';
import { ClientModel } from '../../models/client/client.model';
import { CareFileModel } from '../../models/carefile/carefile.model';
import { Router } from '@angular/router';
import { PageInfoService } from '../pageinfo/page-info.service';
import { UserSettingsModel } from '../../models/user-settings/user-settings.model';
import { UserTypeEnum } from '../../models/user-type/user-type.enum';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_COLUMN,
  DEFAULT_SORT_DIRECTION,
  IMainSubMenuItem,
  PaginationDataModel,
  StatusEnum,
} from '../../models';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { IndividualInvolvementService } from '../individual-involvement/individual-involvement.service';
import { PermissionService } from '../permissions/permission.service';
import { KeycloakService } from 'keycloak-angular';
import { CarefileService } from '../carefile/carefile.service';

export class UserInfoModel {
  id: number | string;
  email: string;
  lastName: string;
  firstName: string;
  fullName: string;
  role: string;
  avatar: string;
  preferredLanguage: string;
  tenantId: string | number;

  constructor(user?: EmployeeModel | ClientModel | ClientNetworkModel) {
    if (user) {
      this.id = user.id;
      this.lastName = user.lastName;
      this.fullName = user.fullName;
      this.tenantId = user.tenantId;

      if (user instanceof ClientNetworkModel) {
        this.fullName = user.prefix ? `${user.givenName} ${user.prefix} ${user.lastName}` : `${user.givenName} ${user.lastName}`;
      } else {
        this.avatar = user.avatar;
      }

      if (user instanceof EmployeeModel) {
        this.email = user.primaryEmail;
        this.preferredLanguage = user.preferredLanguage;
        this.firstName = user.firstName;
      } else {
        this.firstName = user.givenName;
      }
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  public userId: string;
  public tenantId: string | number;
  public tenantTimeZone: string;
  public userType: string;
  public sub: string;
  public avatar: string;
  public get employeeId() {
    return this.userId;
  }
  readonly sideBarRootkey = 'general.menu.Dashboards';

  public userInfo$ = new BehaviorSubject<UserInfoModel>(null);
  public clientInfo$ = new BehaviorSubject<UserInfoModel>(null);
  public userSettings$ = new BehaviorSubject<UserSettingsModel>(null);
  public clients$ = new BehaviorSubject<ClientModel[]>(null);
  public currentClient = new ClientModel();
  public currentCareFile = new CareFileModel();
  public selectedCarefileStatus = new BehaviorSubject<String>(null);

  public userSettings: UserSettingsModel = new UserSettingsModel();

  public tableViews: { [key: string]: ITableView } = {};
  public isClientPortal: boolean = false;
  public currentCarefileServiceId: string;
  public currentCarefileDataFrom: Date;
  private receivedCurrentCarefileDataFromSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public receivedCurrentCarefileDataFrom$: Observable<boolean> =
    this.receivedCurrentCarefileDataFromSubject.asObservable();
  private carefiles: CareFileModel[] = [];

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
    public languageService: LanguageService,
    public pageInfoService: PageInfoService,
    public clientInfoService: ClientInfoService,
    public router: Router,
    private sidebarService: SidebarService,
    private individualInvolvementService: IndividualInvolvementService,
    private permissionService: PermissionService,
    private keycloakService: KeycloakService,
    private carefileService: CarefileService,
  ) {}

  public initUserInfo(token: any) {
    this.userId = token.user_id;
    this.tenantId = token.tenant_id;
    this.userType = token.user_type;
    this.sub = token.sub;
    this.getTenantTimeZone();

    if (!this.userId) {
      return console.error('No user_id attribute defined in Keycloak !');
    }

    if (!this.userType) {
      return console.error('No userType attribute defined in Keycloak !');
    }
    switch (this.userType) {
      case UserTypeEnum.ClientNetwork:
        this.setupClientNetworkUser();
        break;
      case UserTypeEnum.Client:
        this.setupClientUser();
        break;
      case UserTypeEnum.Employee:
        break;
      default:
        return console.error(
          'Invalid userType attribute defined in Keycloak !',
        );
    }
  }

  public updateUserInfo() {
    this.getEmployeeDetail(this.userId).subscribe((employee) => {
      const userInfo = new UserInfoModel(employee);
      this.avatar = employee.avatar;
      this.userInfo$.next(userInfo);
    });
  }

  private getEmployeeDetail(
    employeeId: number | string,
  ): Observable<EmployeeModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/employees/:employee_id',
      entity_type: 'Employee',
      entity_id: employeeId.toString(),
    });
    return this.http
      .get<EmployeeModel>(
        `${this.environment.employeeManagementApiUrl}/${employeeId}`,
        { headers },
      )
      .pipe(map((employee) => new EmployeeModel(employee)));
  }

  public async getEmployeeDetailAsync(
    employeeId: number | string,
  ): Promise<any> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/employees/:employee_id',
      entity_type: 'Employee',
      entity_id: employeeId.toString(),
    });
    return await new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${this.environment.employeeManagementApiUrl}/${employeeId}`,
          { headers },
        )
        .pipe(map((employee) => new EmployeeModel(employee))).subscribe(res => resolve(res));
    })
  }

  public setEmployeeUser(employee) {
      this.languageService.setLanguage(
        employee.preferredLanguage ? employee.preferredLanguage : 'nl',
      );
      const userInfo = new UserInfoModel(employee);
      this.avatar = employee.avatar;
      this.userInfo$.next(userInfo);
      this.pageInfoService.setIsEmployeePortal(true);
      this.pageInfoService.setIsClientSet(false);
      this.pageInfoService.setUserType(this.userType);
  }


  public async setUpUserSettings(
    userId: number | string,
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.getUserSettings(userId).subscribe((data) => {
        this.userSettings = data;
        localStorage.setItem('userSettings', JSON.stringify(data));
        this.userSettings$.next(data);
        resolve(data);
      });
    })
  }

  viewDashboard(menu: IMainSubMenuItem) {
    if (menu?.route?.startsWith('http')) {
      window.open(menu.route, '_blank');
    } else {
      this.router.navigate([menu.route]);
    }
  }

  private getDashboardMenuItem(dashboardName: string) {
    const allMenuItems = this.sidebarService.sidebarItems$.getValue();

    const dashboardMenu =
      allMenuItems
        ?.find((x) => x.name === this.sideBarRootkey)
        ?.items ?? [];
    return dashboardMenu.find(item => item?.name.includes(dashboardName));
  }

  private setupClientUser() {
    this.clientInfoService
      .getClientDetail(this.userId)
      .pipe(
        map((client) => {
          this.languageService.setLanguage(
            client.preferredLanguage ? client.preferredLanguage : 'nl',
          );
          const userInfo = new UserInfoModel(client);
          this.avatar = client.avatar;
          this.userInfo$.next(userInfo);
          this.pageInfoService.setIsEmployeePortal(false);
          this.pageInfoService.setUserType(this.userType);
          this.pageInfoService.setIsClientSet(false);
          return client;
        }),
      )
      .subscribe((client) => {
        this.setupClientData(client);
      });
  }

  public setupClientNetworkUser() {
    this.clientInfoService
      .getClientNetworkDetail(this.userId)
      .pipe(
        map((clientNetworkRes) => {
          const clientNetwork = new ClientNetworkModel(clientNetworkRes);
          this.languageService.setLanguage(
            clientNetwork.preferredLanguage
              ? clientNetwork.preferredLanguage
              : 'nl',
          );
          const userInfo = new UserInfoModel(clientNetwork);
          this.userInfo$.next(userInfo);
          this.pageInfoService.setIsEmployeePortal(false);
          this.pageInfoService.setIsClientSet(false);
          this.pageInfoService.setUserType(this.userType);

          return clientNetwork;
        }),
        concatMap(() => {
          return this.carefileService.getCarefiles(
            new PaginationDataModel('createdAt', 'desc', 999),
            { 'clientNetworks.id': this.userId },
          );
        }),
        concatMap((carefiles) => {
          this.carefiles = carefiles.docs;
          const filterObj = {
            status: 'active',
            'clientNetwork.id': this.userId,
            type: UserTypeEnum.ClientNetwork,
            allInvolvements: true,
            carefileId: carefiles.docs[0].id,
          };
          return this.individualInvolvementService.getCarefileAccessManagements(
            new PaginationDataModel('createdAt', 'desc', 999),
            filterObj,
          );
        }),
        concatMap((involvements) => {
          const involvementCarefileIds: string[] = involvements.docs.map(
            (i) => i.carefileId,
          );
          const clientCarefiles = this.carefiles.filter((c) =>
            involvementCarefileIds.includes(c.id),
          );
          const clients = clientCarefiles.map((carefile) => {
            return carefile.clientId;
          });
          return this.clientInfoService.getCarefileRelatedClients(clients);
        }),
      )
      .subscribe(
        (clients) => {
          this.clients$.next(clients);
          const clientSession = this.clientInfoService.getClientSession();
          if (clientSession) {
            this.setupClientData(
              clients.find((client) => {
                return client.id === clientSession.client_id;
              }),
            );
          } else if (clients.length === 1) {
            this.setupClientData(clients[0]);
          }
        },
        (error) => console.log(error),
      );
  }

  public setupClientData(client: ClientModel) {
    this.currentClient = client;
    this.clientInfoService
      .getCarefileDetail(client.id)
      .pipe(
        map((response: any) => {
          return response.docs;
        }),
      )
      .subscribe((careFiles: any[]) => {
        if (careFiles.length > 0) {
          const careFile = careFiles.find(
            (cf) =>
              cf.status === StatusEnum.Open || cf.status === StatusEnum.Closed,
          );
          this.clientInfoService.storeClientSession(client.id, careFile.id);
          this.currentCareFile = careFile;
          this.pageInfoService.setIsClientSet(true);
          this.pageInfoService.setClientNumber(client.clientNumber);
          this.pageInfoService.setCareStatus(careFile.status);
          this.pageInfoService.setClientName(client.fullName);
          this.pageInfoService.setClientAssignedTo(careFile.employees);
          this.pageInfoService.setClientDateOfBirth(careFile.clientDateOfBirth);
          this.selectedCarefileStatus.next(careFile.status);
          this.clientNetworkFunctionSelection();
        }
      });
  }

  public updatePreferredLanguage(preferredLanguage: string) {
    switch (this.userType) {
      case UserTypeEnum.Employee:
        this.updateEmployeePreferredLanguage(preferredLanguage);
        break;
      case UserTypeEnum.ClientNetwork:
        this.updateClientNetworkPreferredLanguage(preferredLanguage);
        break;
      case UserTypeEnum.Client:
        this.updateClientPreferredLanguage(preferredLanguage);
        break;
      default:
        this.languageService.setLanguage('nl');
    }
  }

  getTenantTimeZone() {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/tenants/timezone',
      entity_type: 'Tenant',
      tentity_id: this.tenantId?.toString(),
    });
    return this.http
      .get<any>(`${this.environment.tenantManagementApiUrl}/timezone`, {
        headers,
      })
      .pipe(
        map((tenantData) => {
          this.tenantTimeZone = tenantData;
          localStorage.setItem(
            'tenantTimeZone',
            JSON.stringify(this.tenantTimeZone),
          );
          return;
        }),
      )
      .subscribe();
  }

  private updateEmployeePreferredLanguage(preferredLanguage: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/employees/:employee_id/update-language',
      entity_type: 'Employee',
      entity_id: this.userId.toString(),
    });
    this.http
      .patch<any>(
        `${this.environment.employeeManagementApiUrl}/${this.userId}/update-language`,
        { preferredLanguage },
        { headers },
      )
      .pipe(map((employee) => new EmployeeModel(employee)))
      .subscribe((employee) => {
        const userInfo = new UserInfoModel(employee);
        this.userInfo$.next(userInfo);
      });
  }

  private updateClientNetworkPreferredLanguage(preferredLanguage: string) {
    this.clientInfoService
      .updateClientNetworkLanguage(preferredLanguage, this.userId)
      .subscribe((clientNetwork) => {
        const userInfo = new UserInfoModel(clientNetwork);
        this.userInfo$.next(userInfo);
        this.pageInfoService.setIsEmployeePortal(false);
      });
  }

  private updateClientPreferredLanguage(preferredLanguage: string) {
    this.clientInfoService
      .updateClientLanguage(preferredLanguage, this.userId)
      .subscribe((client) => {
        const userInfo = new UserInfoModel(client);
        this.userInfo$.next(userInfo);
        this.pageInfoService.setIsEmployeePortal(false);
      });
  }

  private getUserSettings(
    employeeId: number | string,
  ): Observable<UserSettingsModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/employees/settings',
      entity_type: 'Employee',
      entity_id: employeeId.toString(),
    });
    return this.http.get<UserSettingsModel>(
      `${this.environment.employeeManagementApiUrl}/settings`,
      { headers },
    );
  }

  private updateUserSettings(settings: UserSettingsModel) {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/employees/settings',
      entity_type: 'Employee',
      entity_id: settings.employeeId,
    });
    localStorage.setItem('userSettings', JSON.stringify(settings));
    return this.http.patch<UserSettingsModel>(
      `${this.environment.employeeManagementApiUrl}/settings`,
      settings,
      {
        headers: httpHeaders,
      },
    );
  }

  private clientNetworkFunctionSelection() {
    let filterObj = {
      status: 'active',
      'clientNetwork.id': this.userId,
      carefileId: this.currentCareFile.id,
      type: this.userType,
    };

    if (this.userType === UserTypeEnum.Client) {
      delete filterObj['clientNetwork.id'];
      filterObj['client.id'] = this.userId;
    }

    this.individualInvolvementService
      .getCarefileAccessManagements(
        new PaginationDataModel('createdAt', 'desc', 999),
        filterObj,
      )
      .subscribe((involvements) => {
        const involvement = involvements.docs[0];
        this.currentCarefileServiceId = involvement.carefileService.id;
        this.currentCarefileDataFrom = involvement.carefileDataFrom;
        this.receivedCurrentCarefileDataFromSubject.next(true);
        this.permissionService.setClientRole([involvement.function]);
        this.keycloakService.getToken().then((tokenData) => {
          this.permissionService
            .initRolePermissions(tokenData)
            .subscribe(() => {
              this.sidebarService.mainMenuItems$.subscribe((sidebarItem) => {
                const item = sidebarItem?.find(
                  (el) => el.hasPermission && el.route !== 'carefiles',
                );
                if (item) this.router.navigate([item.route]);
                return;
              });
            });
        });
      });
  }

  public getUserCarefiles(userId: string) {
    return this.carefileService.getCarefiles(
      new PaginationDataModel('createdAt', 'desc', 999),
      { 'clientNetworks.id': userId },
    );
  }

  public getClientCarefiles(userId: string) {
    return this.clientInfoService.getCarefileDetail(userId);
  }

  public getFiltersForPage(page: string): any {
    if (!this.userSettings.pages) this.userSettings.pages = {};
    if (!this.userSettings.pages[page]) {
      this.userSettings.pages[page] = {};
      this.userSettings.pages[page].filters = {};
      this.userSettings.pages[page].view = 'list';
    }
    return this.userSettings.pages[page].filters;
  }

  public setFiltersForPage(page: string, filters: any): void {
    if (!this.userSettings.pages[page]) {
      this.userSettings.pages[page] = {};
      this.userSettings.pages[page].filters = {};
    }
    this.userSettings.pages[page].filters = filters;

    this.updateUserSettings(this.userSettings).subscribe();
  }

  public hasFilters(page: string): boolean {
    this.userSettings = JSON.parse(localStorage.getItem('userSettings'));
    if (!this.userSettings.pages) return false;
    if (!this.userSettings.pages[page]) return false;

    for (let filter in this.userSettings.pages[page].filters) {
      if (
        this.userSettings.pages[page].filters[filter] &&
        this.userSettings.pages[page].filters[filter] != ''
      )
        return true;
    }
    return false;
  }

  public setViewForPage(page: string, view: string) {
    if (!this.userSettings.pages[page]) {
      this.userSettings.pages[page] = {};
    }
    this.userSettings.pages[page].view = view;

    this.updateUserSettings(this.userSettings).subscribe();
  }

  public getViewForPage(page: string): string {
    this.userSettings = JSON.parse(localStorage.getItem('userSettings'));
    if (!this.userSettings.pages) this.userSettings.pages = {};
    if (!this.userSettings.pages[page]) {
      this.userSettings.pages[page] = {};
      this.userSettings.pages[page].filters = {};
      this.userSettings.pages[page].view = 'list';
    }
    return this.userSettings.pages[page].view || 'list';
  }

  private checkDashboardForPageStructure(
    page: string,
    dashboardName: string,
  ): void {
    if (!this.userSettings || !this.userSettings.pages) return;

    if (!this.userSettings.pages[page]) this.userSettings.pages[page] = {};

    if (!this.userSettings.pages[page].dashboard)
      this.userSettings.pages[page].dashboard = {};

    if (!this.userSettings.pages[page].dashboard[dashboardName])
      this.userSettings.pages[page].dashboard[dashboardName] = [];
  }

  public setDashboardForPage(
    page: string,
    dashboardName: string,
    dashboardSettings: any,
  ) {
    this.checkDashboardForPageStructure(page, dashboardName);

    this.userSettings.pages[page].dashboard[dashboardName] = dashboardSettings;
    this.updateUserSettings(this.userSettings).subscribe();
  }

  public setUpLandingPage(dashboardName: string) {
     this.userSettings.pages = this.userSettings.pages ?? {};
     this.userSettings.pages.Homepage = this.userSettings.pages.Homepage ?? {};

    this.userSettings.pages['Homepage'].activeDashboard = dashboardName;
    this.updateUserSettings(this.userSettings).subscribe();
  }

  public getDashboardForPage(page: string, dashboardName: string): any {
    this.checkDashboardForPageStructure(page, dashboardName);

    return this.userSettings.pages[page].dashboard[dashboardName];
  }

  public setTableViewForPage(page: string, tableView: ITableView): void {
    if (!this.userSettings.pages[page]) {
      this.userSettings.pages[page] = {};
      this.userSettings.pages[page].tableView = {};
    }
    this.userSettings.pages[page].tableView = tableView;
    this.updateUserSettings(this.userSettings).subscribe();
  }

  public getTableViewForPage(page: string): ITableView {
    this.userSettings = JSON.parse(localStorage.getItem('userSettings'));
    if (!this.userSettings.pages) this.userSettings.pages = {};
    if (!this.userSettings.pages[page]) {
      this.userSettings.pages[page] = {};
      this.userSettings.pages[page].tableView = {
        pageIndex: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        sortDirection: DEFAULT_SORT_DIRECTION,
        sortColumn: DEFAULT_SORT_COLUMN,
      };
    }
    if (this.userSettings.pages[page].tableView)
      this.userSettings.pages[page].tableView.search = null;
    return this.userSettings.pages[page].tableView;
  }
}
