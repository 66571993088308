<div class="sidesheet-tooltip-container" *ngIf="confirmAction">
    <span [ngStyle]="confirmAction?.contentStyle">
        <div class="sidesheet-tooltip-header" [ngStyle]="confirmAction?.headerStyle">
            {{ confirmAction?.textHeader | translate }}
        </div>
        <p class="sidesheet-tooltip-text">
            {{ confirmAction?.textContent | translate }}
        </p>
        <div class="btn-container">
            <div *ngIf="confirmAction?.textOk">
                <button translate class="c4p-button-ok sidesheet-tooltip-button" type="submit"
                    (click)="onExecuteButtonAction(true)">
                    {{ confirmAction?.textOk | translate }}
                </button>
            </div>

            <div *ngIf="confirmAction?.textCancel">
                <button class="c4p-button-cancel sidesheet-tooltip-button" type="submit"
                    (click)="onExecuteButtonAction(false)">
                    {{ confirmAction?.textCancel | translate }}
                </button>
            </div>
        </div>
    </span>
</div>