<div [ngClass]="data.smallPopup ? 'c4p-popup-form-small' : 'c4p-popup'">
  <!-- title -->
  <div class="c4p-popup-title-small">
    <span class="c4p-horizontal-divider" translate>{{ data.title }}</span>
  </div>

  <!-- gate keeper reason -->
  <div *ngIf="data.reasonLabel" class="c4p-popup-content-top">
    <div class="c4p-popup-content-left-part">

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <rect x="6" y="6" width="26" height="26" rx="13" stroke="#D92D20" stroke-width="2"/>
        </g>
        <g opacity="0.1">
          <rect x="1" y="1" width="36" height="36" rx="18" stroke="#D92D20" stroke-width="2"/>
        </g>
        <g clip-path="url(#clip0_2177_57054)">
          <path d="M18.9998 15.6665V18.9998M18.9998 22.3332H19.0082M27.3332 18.9998C27.3332 23.6022 23.6022 27.3332 18.9998 27.3332C14.3975 27.3332 10.6665 23.6022 10.6665 18.9998C10.6665 14.3975 14.3975 10.6665 18.9998 10.6665C23.6022 10.6665 27.3332 14.3975 27.3332 18.9998Z" stroke="#D92D20" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2177_57054">
            <rect width="20" height="20" fill="white" transform="translate(9 9)"/>
          </clipPath>
        </defs>
      </svg>

    </div>
    <div class="c4p-popup-content-right-part">
      <p class="c4p-font-large">
        <span translate>{{ data.reasonLabel }}</span>
      </p>
      <div class="c4p-dimmed c4p-font-medium">
        <span translate>general.labels.MeaningfulChanges</span>
      </div>
      <mat-form-field appearance="fill">
        <textarea
          matInput
          class="c4p-form-text-area-gate-keeper"
          [(ngModel)]="reason"
          required
          style="height: 150px"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- content -->
  <div class="c4p-popup-content-down" *ngIf="data.message">
    <div class="c4p-popup-content-left-part">
      <img style="width: 27px;margin-top: 2px;" src="/assets/svg/false-icon.svg" alt="">
    </div>

    <div class="c4p-popup-content-right-part">
      <p>
        <span translate>{{ data.message }} </span>
      </p>
    </div>
  </div>

  <!-- buttons -->
  <div style="display: flex">
    <span
      *ngIf="!data.onlyConfirmation"
      class="c4p-button-cancel"
      (click)="onDismiss()"
      >{{
        (data.cancelText ? data.cancelText : 'general.labels.Cancel')
          | translate
      }}</span
    >
    <span
      *ngIf="(data.reasonLabel && reason) || !data.reasonLabel"
      class="c4p-button-ok"
      (click)="onConfirm()"
      translate
      >{{ data.confirmText }}</span
    >
  </div>
</div>
